import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Card, Grid, Group, Title } from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { shallow } from "zustand/shallow";

import EffortBuilderForm from "./EffortBuilderForm";
import EffortFieldReorder from "./EffortFieldReorder";
import EffortFields from "./EffortFields";
import { PublishButton } from "@components/Effort/EffortStatus";
import { SaveFormButton } from "@components/Effort/EffortSavedForms";
import { generateEffortFieldRequest } from "./helpers";

import useAppStore from "./effort-builder-store";

export default function EffortBuilder({
  effortId,
  fetchEffortData,
  showPublish,
  effortName,
  postmarkTemplateNames,
  language,
  entityName,
}) {
  const queryClient = useQueryClient();
  const [config, setConfig] = useState({});
  const [eiWithTriggerId, setEiWithTriggerId] = useState(null);

  const { effortState, setEffortState, fields } = useAppStore(
    (state) => ({
      effortState: state.effortState,
      setEffortState: state.setEffortState,
      fields: state.effortState.fields,
    }),
    shallow
  );

  const queryKey = `effort${effortId}Configuration`;
  const eiWithTriggerQueryKey = `eiWithTrigger${effortId}`;

  const fetchData = () => queryClient.invalidateQueries([queryKey]);
  const fetchEiWithTrigger = () =>
    queryClient.invalidateQueries([eiWithTriggerQueryKey]);

  const { triggerFailureReason, data: triggerData } = useQuery({
    queryKey: [eiWithTriggerQueryKey],
    queryFn: async () =>
      axios
        .post(`/efforts/${effortId}/data-grabber/`, {
          context: "cond_reg_engage_item",
        })
        .then(({ data }) => {
          setEiWithTriggerId(data.response[0].engagement_item_id || null);
          return data.response[0];
        })
        .catch((err) => {
          setEiWithTriggerId(null);
          throw err;
        }),
  });

  const { failureReason, data: info } = useQuery({
    queryKey: [queryKey],
    queryFn: async () =>
      axios
        .get(`/efforts/${effortId}/configuration/`)
        .then(({ data }) => {
          const draftConfig = data.response[0];

          if (!draftConfig) return;

          setConfig(draftConfig);

          if (!draftConfig.fields || !draftConfig.fields.length) {
            setEffortState({
              title: "",
              description: "",
              fields: [],
            });
          } else {
            setEffortState({
              title: "",
              description: "",
              fields: draftConfig.fields,
            });
          }

          return data.response[0];
        })
        .catch((err) => {
          throw err;
        }),
  });

  function onUpdate(newState) {
    setEffortState({ ...effortState, ...newState });
    updateConfig({ ...effortState, ...newState });
  }

  function updateConfig(newConfig = effortState) {
    const req = {
      configuration: { ...config, ...newConfig },
    };

    axios
      .put(`/efforts/${effortId}/update-config/`, req)
      .then(() => {
        toast.success("Saved!");
        fetchEffortData();
        fetchData();
        fetchEiWithTrigger();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onFormSubmit(e) {
    generateEffortFieldRequest(
      fields,
      e,
      {
        first_name: "John",
        last_name: "Smith",
        id: "123-abc",
      },
      true
    ).then((newReq) => {
      console.log(newReq);
    });
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <div>
            <EffortFields
              effortId={effortId}
              onChange={(newFields) => onUpdate({ fields: newFields })}
              updateConfig={updateConfig}
              postmarkTemplateNames={postmarkTemplateNames}
              eiWithTriggerId={eiWithTriggerId}
              entityName={entityName}
              effortName={effortName}
            />
          </div>
          <Group mt="lg">
            <SaveFormButton effortName={effortName} fields={fields} />
            <EffortFieldReorder
              onUpdate={(newFields) => onUpdate({ fields: newFields })}
            />
            <Button onClick={() => updateConfig()} color="green">
              Save Work
            </Button>
            {showPublish && (
              <PublishButton
                buttonProps={{
                  size: "sm",
                  variant: "light",
                  color: "green",
                }}
                effortId={effortId}
                onSuccess={() => true}
              />
            )}
          </Group>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title order={3} mb="sm">
            Preview
          </Title>
          <Card style={{ overflow: "visible" }}>
            <EffortBuilderForm
              language={language}
              fields={fields}
              onSubmit={(e) => {
                onFormSubmit(e);
              }}
            />
          </Card>
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
}
