import React, { useState } from "react";

import {
  Badge,
  Image,
  Divider,
  Group,
  Card,
  Modal,
  Text,
  Button,
} from "@mantine/core";

import { GeospotForm } from "./";

import generateStaticMap from "@util/generateStaticMap";

export default function GeospotItem({ editable, data, fetchData, id }) {
  const [editing, setEditing] = useState(false);
  if (!data) return null;

  function onClose() {
    setEditing(false);
  }

  return (
    <Card
      mb="xs"
      style={{
        overflow: "hidden",
      }}
    >
      {data.latitude && data.longitude && (
        <Card.Section mb="sm">
          <Image
            height={250}
            // mah={250}
            alt={`${data.name} map`}
            style={{
              overflow: "hidden",
            }}
            src={generateStaticMap(
              { latitude: data.latitude, longitude: data.longitude },
              { width: 500, height: 250 }
            )}
          />
        </Card.Section>
      )}
      <Text fw={700}>{data.name}</Text>
      <Text c="dimmed">
        {data.address_1} {data.city}, {data.state_province} {data.zip}
      </Text>
      {(data.children || editable) && (
        <Divider mt="xs" mb="xs" opacity={0.25} />
      )}
      <Group>
        {data.children && <React.Fragment>{data.children}</React.Fragment>}
        {editable && (
          <Button
            color="gray"
            variant="light"
            size="xs"
            onClick={() => setEditing(true)}
          >
            edit
          </Button>
        )}
      </Group>
      <Modal opened={editing} onClose={onClose} title="Update Geo-Spot">
        <GeospotForm
          id={id}
          name={data.name}
          address1={data.address_1}
          address2={data.address_2}
          city={data.city}
          state={data.state_province}
          zip={data.zip}
          country={data.country}
          onSuccess={() => {
            fetchData();
            onClose();
          }}
        />
      </Modal>
    </Card>
  );
}
