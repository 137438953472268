import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  List,
  Divider,
  Modal,
  Button,
  Grid,
  Text,
  Group,
  Title,
  Box,
} from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { t } from "i18next";

import EffortBuilderForm from "@components/EffortBuilder/EffortBuilderForm";
import {
  populateEffortFields,
  generateEffortFieldRequest,
} from "@components/EffortBuilder/helpers";
import { MicrositeUserEmailStreams } from "@components/Email";
import { ImageSlider } from "@components/shared";
import ViewLoader from "./ViewLoader";
import RideChallengeHeader from "./RideChallengeHeader";
import getGeoLocation from "@util/getGeoLocation";
import useAppStore from "./ride-challenge-store";

export default function EffortView({ preview }) {
  const [effortData, setEffortData] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    coords,
    email,
    effortId,
    emailStreams,
    entityInfo,
    location,
    phone,
    effort,
    user,
    verificationMethod,
    setActiveStep,
    setEmailStreams,
    setUser,
    setRegistrationInfo,
  } = useAppStore(
    (state) => ({
      appConfig: state.appConfig,
      coords: state.coords,
      effortId: state.effortId,
      email: state.email,
      emailStreams: state.emailStreams,
      entityInfo: state.entityInfo,
      location: state.location,
      phone: state.phone,
      effort: state.effort,
      setEmailStreams: state.setEmailStreams,
      setRegistrationInfo: state.setRegistrationInfo,
      setActiveStep: state.setActiveStep,
      setUser: state.setUser,
      user: state.user,
      verificationMethod: state.verificationMethod,
    }),
    shallow
  );

  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    const req = {
      campaign_effort_id: effort ? effort.id : effortId,
    };

    if (preview) req.preview = true;
    if (location) {
      req.location_id = location.id;
    }

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    axios
      .post(`/microsite/effort-data/`, req)
      .then(({ data }) => {
        setEffortData({
          ...data.response[0],
          configuration: data.response[0].configuration
            ? data.response[0].configuration
            : {},
        });
        setLoading(false);
      })
      .catch((err) => {
        setEffortData(null);
      });
  }

  function onFormSubmit(formData) {
    const hpField = document.getElementById("requiredReferralId");

    if (hpField && hpField.value) {
      return alert(
        "Success! The form has been submitted. You may now close this page!"
      );
    }

    // if (isAdmin || isManager)
    //   return alert(
    //     `If we did let this form actually submit, the data that would've been submitted would've looked something like:\n\n${JSON.stringify(
    //       formData
    //     )}\n\nFortunately, this didn't submit because you're logged in.`
    //   );

    setSubmitting(true);

    generateEffortFieldRequest(fields, formData, user)
      .then((newReq) => {
        submitForm(newReq, formData);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  }

  function submitForm(reqData, formData) {
    const req = {
      ...reqData,
      campaign_effort_id: effort ? effort.id : effortId,
      submission_method: "form",
    };

    if (user) req.user_id = user.id;

    if (!req.demographics.email) {
      if (email) {
        req.demographics.email = email;
      } else {
        if (user && user.email) {
          req.demographics.email = user.email;
        }
      }
    }
    if (phone) req.demographics.mobile_phone = phone;
    if (verificationMethod) req.verification_method = verificationMethod;
    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    if (location) {
      req.location_id = location.id;

      if (location.present_at_location) req.present_at_location = true;

      if (location.geo_spot_id) {
        req.campaign_effort_geo_spot_id = location.geo_spot_id;
        req.geo_spot_id = location.geo_spot_id;
      }
    }

    if (!user) {
      req.email_streams = {
        ...emailStreams.reduce((acc, cur) => {
          acc[cur.name] = cur.value;
          return acc;
        }, {}),
      };
    }

    setSubmitting(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/ride-challenge/register/`, req)
        .then(({ data }) => {
          const res = data.response[0];
          setSubmitting(false);
          toast.success("Success!");

          if (entityInfo.pixel_code && window.fbq) {
            window.fbq("trackCustom", "FormSubmit");
          }

          if (!user) {
            setUser(res.user_lookup);
          }

          setRegistrationInfo(res);
          setActiveStep("membership");
        })
        .catch((err) => {
          toast.error(err);
          setSubmitting(false);
        });
    });
  }

  if (loading) return <ViewLoader />;

  if (!effortData || !effort) return null;

  const effortAssets = effortData.assets;
  const { fields } = effortData.configuration;
  const assets = effortData.configuration.assets
    ? effortData.configuration.assets
        .map((m) => ({
          ...m,
          asset: effortAssets.find((f) => f.id === m.id),
        }))
        .filter((f) => f.asset)
    : [].filter((f) => f.asset);
  const sliderImages = assets.filter((f) => f.placement === 2);

  let fieldsToDisplay = populateEffortFields(fields, user, {
    phone: phone,
    email,
  });

  return (
    <div>
      <Box mb="sm">
        <RideChallengeHeader />
      </Box>
      {sliderImages.length > 0 && (
        <ImageSlider
          items={[
            ...sliderImages.map((m) => ({
              src: m.asset.filename_url,
              external_link_url: m.asset.external_link_url,
              key: m.id,
            })),
          ]}
        />
      )}
      <Title align="center" mt="sm" c="white" order={2}>
        {effortData.title}
      </Title>
      {user && (
        <Text align="center" mb="lg" size="lg" color="white" mt="sm">
          {user.first_name
            ? `${t("user_welcome_title")}, ${user.first_name}!`
            : `${t("user_welcome_title")}!`}
        </Text>
      )}
      <Grid gutter={30}>
        <Grid.Col span={{ base: 12 }}>
          <React.Fragment>
            {fieldsToDisplay && fieldsToDisplay.length > 0 && (
              <EffortBuilderForm
                fields={fieldsToDisplay}
                onSubmit={onFormSubmit}
                loading={submitting}
                buttonText={t("effort_form_submit_button")}
                language="en"
              />
            )}
          </React.Fragment>
        </Grid.Col>
      </Grid>
      <>
        <Divider mt="lg" mb="lg" />
        <MicrositeUserEmailStreams
          user={user}
          streams={emailStreams}
          onChange={(e) => {
            setEmailStreams(e);
          }}
        />
      </>
    </div>
  );
}
