import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Flex, ActionIcon, TextInput, Button } from "@mantine/core";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import { IconArrowRight } from "@tabler/icons-react";
import styled from "styled-components";

import EntrySummary from "./EntrySummary";
import entityIcon from "@util/entityIcon";
import getGeoLocation from "@util/getGeoLocation";
import useAppStore from "./ride-challenge-store";

const RideChallengeKeywordForm = ({ onSuccess, effortId }) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  const { t } = useTranslation();

  const { coords, user, location } = useAppStore(
    (state) => ({
      coords: state.coords,
      user: state.user,
      location: state.location,
    }),
    shallow
  );

  if (!effortId) return null;

  function onSubmit() {
    if (!value) {
      return;
    }

    const req = {
      keyword: value.trim(),
      campaign_effort_id: effortId,
      user_id: user.id,
    };

    if (coords) {
      req.latitude = coords.latitude;
      req.longitude = coords.longitude;
    }

    req.location_id = location?.id;

    setLoading(true);

    getGeoLocation().then((newCoords) => {
      if (newCoords) {
        req.latitude = newCoords.latitude;
        req.longitude = newCoords.longitude;
      }

      axios
        .post(`/ride-challenge/submit-keyword/`, req)
        .then(({ data }) => {
          setLoading(false);
          if (data.response[0]) {
            setSummary(data.response[0]);
          } else {
            toast.success(t("keyword_entry_success"));
            onSuccess();
            setValue("");
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    });
  }

  return (
    <div>
      {summary && (
        <EntrySummary
          keyword={value}
          results={summary}
          onClose={() => {
            setValue("");
            onSuccess();
            setSummary(null);
          }}
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!value) return;
          onSubmit();
        }}
      >
        <Flex>
          <StyledInput>
            <TextInput
              radius="md"
              placeholder={t("keyword_input_placeholder")}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              size="lg"
              maxLength={50}
            />
          </StyledInput>
          <ActionIcon
            size="xl"
            disabled={loading}
            onClick={() => {
              if (!value) return;
              onSubmit();
            }}
            color="dark"
            loading={loading}
            style={{
              height: "var(--input-height-lg)",
              borderRadius:
                "0 var(--mantine-radius-md) var(--mantine-radius-md) 0",
            }}
          >
            <IconArrowRight size={28} />
          </ActionIcon>
        </Flex>
      </form>
    </div>
  );
};

export default RideChallengeKeywordForm;

const StyledInput = styled.div`
  flex-grow: 1;
  input {
    background-color: var(--mantine-color-gray-3);
    border-radius: var(--mantine-radius-md) 0 0 var(--mantine-radius-md);
  }

  input::placeholder {
    color: var(--mantine-color-gray-6);
  }
`;
