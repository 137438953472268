import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import {
  ActionIcon,
  UnstyledButton,
  Flex,
  Text,
  Button,
  Divider,
  Box,
  Badge,
  NumberInput,
  Loader,
} from "@mantine/core";
import axios from "axios";
import toast from "react-hot-toast";
import styled from "styled-components";
import { IconCheck, IconX, IconCopy } from "@tabler/icons-react";
import { AnimatePresence, motion } from "framer-motion";

import ViewTitle from "./ViewTitle";
import { VinForm } from "@components/RideChallenge/VinSetup";
import DealerVerification from "./DealerVerification";
import useAppStore from "./ride-challenge-store";
import VehicleCard from "./VehicleCard";
import OdometerValueMismatch from "./OdometerValueMismatch";
import RideChallengeHeader from "./RideChallengeHeader";
import DealerMode from "./DealerMode";

import { nhtsaMakeIsValid, validManufacturers } from "./helpers";

export default function VehicleVerificationView() {
  const [dealerView, setDealerView] = useState(false);
  const [dealerCodeData, setDealerCodeData] = useState(null);
  const [unverifiedData, setUnverifiedData] = useState([]);

  const {
    activeStep,
    vehicleInfo,
    setActiveStep,
    verificationCode,
    coords,
    effortId,
    setVehicleInfo,
    registrationInfo,
    email,
    phone,
    setUser,
    location,
    user,
  } = useAppStore(
    (state) => ({
      activeStep: state.activeStep,
      coords: state.coords,
      effortId: state.effortId,
      registrationInfo: state.registrationInfo,
      vehicleInfo: state.vehicleInfo,
      user: state.user,
      verificationCode: state.verificationCode,
      setUser: state.setUser,
      setActiveStep: state.setActiveStep,
      setVehicleInfo: state.setVehicleInfo,
      location: state.location,
      email: state.email,
      phone: state.phone,
    }),
    shallow
  );

  useEffect(() => {
    if (verificationCode) {
      setDealerCodeData(verificationCode);
      setDealerView(true);
      fetchUser();
    }
  }, []);

  function fetchUser() {
    const req = {
      ...coords,
      location_id: location?.id,
      campaign_effort_id: effortId,
      user_id: user?.id,
    };

    if (email) req.email = email;
    if (phone) req.mobile_phone = phone;

    axios
      .post(`/ride-challenge/retrieve-unverified-readings/`, req)
      .then(({ data }) => {
        const res = data.response;
        const matchingEfforts = res.filter(
          (f) => f.campaign_effort_id === effortId
        );
        if (matchingEfforts.length) {
          setUnverifiedData(matchingEfforts);
        } else {
          setActiveStep("dashboard");
        }
      })
      .catch((err) => {});
  }

  if (dealerView) {
    if (!dealerCodeData) {
      return (
        <div>
          <RideChallengeHeader />
          <ViewTitle
            title={"Verification"}
            subtitle={`Dealer view verification`}
          />
          <DealerVerification
            setDealerCodeData={(e) => {
              setDealerCodeData(e);
              fetchUser();
            }}
            codeVerificationData={{
              ...coords,
              user_location_id: user?.user_location_id,
              campaign_effort_id: effortId,
              location_id: location?.id,
              user_id: user?.id,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <RideChallengeHeader />
        {/* <ViewTitle
          title={`Welcome back!`}
          subtitle={`Please verify the following:`}
        /> */}
        {unverifiedData.map((m, i) => (
          <div key={m.id}>
            <UnverifiedItem
              info={m}
              dealerCodeData={dealerCodeData}
              additionalReqData={{
                ...coords,
                location_id: location?.id,
                verifier_remote_code_id: dealerCodeData.id,
                campaign_effort_id: effortId,
              }}
              fetchUser={fetchUser}
              verificationCode={verificationCode}
            />
            {i < unverifiedData.length - 1 && <Divider mt="lg" mb="lg" />}
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <RideChallengeHeader />
      <ViewTitle
        title={"Verification"}
        subtitle={`Let's get everything verified`}
      />
      <div>
        <Text c="white" align="center">
          Please find an authorized dealership validator to complete the next
          steps.
        </Text>
        <Flex justify="center" mt="xl">
          <Button size="xs" onClick={() => setDealerView(true)}>
            Found a validator/I'm the validator
          </Button>
        </Flex>
      </div>
    </div>
  );
}

export const UnverifiedItem = ({
  info,
  additionalReqData,
  fetchUser,
  verificationCode,
  dealerCodeData,
}) => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdding, setAdding] = useState(false);
  const [override, setOverride] = useState(false);
  const [showReading, setShowReading] = useState(false);

  useEffect(() => {
    if (verificationCode) {
      if (info.odometer_value) {
        switch (`${info.odometer_value}`.length) {
          case 1:
            setValue(`00000${info.odometer_value}`);
            break;
          case 2:
            setValue(`0000${info.odometer_value}`);
            break;
          case 3:
            setValue(`000${info.odometer_value}`);
            break;
          case 4:
            setValue(`00${info.odometer_value}`);
            break;
          case 5:
            setValue(`0${info.odometer_value}`);
            break;
          default:
            setValue(`${info.odometer_value}`);
            break;
        }
        // setValue(info.odometer_value);
      }
    }
  }, []);

  const vinVerified = info?.nhtsa_results?.success;
  const isLegacyVin = info && parseInt(info.year) < 1981;
  const validMake = isLegacyVin
    ? true
    : nhtsaMakeIsValid(info.nhtsa_results?.response);
  const reqData = {
    ...additionalReqData,
    ride_challenge_data_point_id: info.id,
    user_interaction_id: info.user_interaction_id,
  };
  function resetVerify() {
    setValue("");
    setShowReading(false);
  }

  function onSubmit() {
    setLoading(true);

    const nhtsaData = getDataFromNhtsaResults(
      info.nhtsa_results?.response
    ).reduce((acc, cur) => {
      acc[cur.server_key] = cur.value;
      return acc;
    }, {});

    const req = {
      ...reqData,
      ...nhtsaData,
      checksum_results: info.checksum_results,
      nhtsa_results: info.nhtsa_results,
      odometer_value: value,
      vin: info.vin,
    };

    if (isLegacyVin) {
      req.year = info.year;
      req.model = info.model;
      req.make = info.make;
    }

    axios
      .post(`/ride-challenge/verify-reading/`, req)
      .then(() => {
        toast.success("Verified!");
        setLoading(false);
        fetchUser();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  if (isAdding) {
    return (
      <DealerMode dealerCodeData={dealerCodeData}>
        <Text c="red" fw={600} align="center">
          EDIT MODE
        </Text>
        <AddVin
          isOverride={override}
          onSuccess={() => fetchUser()}
          additionalReqData={reqData}
          originalData={info}
        />
        {/* <Divider mt="lg" mb="lg" /> */}
        <Flex justify="center" mt="xl">
          <Button
            c="dark"
            color="gray.1"
            size="xs"
            radius="lg"
            onClick={() => {
              setAdding(false);
              resetVerify();
            }}
          >
            cancel changes
          </Button>
        </Flex>
      </DealerMode>
    );
  }

  return (
    <DealerMode dealerCodeData={dealerCodeData}>
      <Text mb="xl" c="white" fw={600} align="center">
        Please confirm the following:
      </Text>
      <Flex gap="xs" align="center">
        <Text
          size="xl"
          fw={600}
          c="white"
          style={{ textTransform: "uppercase" }}
        >
          VIN: {info.vin}
        </Text>
        <ActionIcon
          size="sm"
          color="gray"
          variant="light"
          onClick={() => {
            navigator.clipboard.writeText(info.vin);
            toast.success("Copied!");
          }}
        >
          <IconCopy style={{ width: "70%", height: "70%" }} />
        </ActionIcon>
      </Flex>
      {verificationCode && (
        <Text size="xl" fw={600} c="white">
          {info.odometer_value} miles
        </Text>
      )}
      {isLegacyVin && <VehicleCard info={info} />}
      {!isLegacyVin &&
        info.nhtsa_results &&
        info.nhtsa_results.success !== undefined && (
          <NhtsaResults results={info.nhtsa_results} />
        )}
      {showReading && (
        <Box mt="lg">
          <StyledOdometerForm>
            <OdometerForm value={value} onChange={(e) => setValue(e)} />
            {info.odometer_value && (
              <OdometerValueMismatch
                originalValue={info.odometer_value}
                newValue={value}
              />
            )}
            <Flex mt="lg">
              <Button onClick={onSubmit} loading={loading} disabled={!value}>
                Verify
              </Button>
            </Flex>
            {loading && (
              <Text mt="lg" size="xs" fw={600} c="dimmed" align="center">
                Thank you for your patience. This may take up to 15 seconds.
              </Text>
            )}
          </StyledOdometerForm>
        </Box>
      )}
      {!validMake && (
        <Text mt="lg" c="red" fw={600} align="center">
          {makeErrorMessage}
        </Text>
      )}
      {(vinVerified || isLegacyVin) && !showReading && validMake && (
        <Button
          fullWidth
          mt="md"
          mb="xl"
          onClick={() => {
            setShowReading(true);
          }}
        >
          Confirm
        </Button>
      )}
      <Flex
        justify="center"
        gap="md"
        align="center"
        direction={{ base: "column", xs: "row" }}
        mt="lg"
      >
        <Button
          c="dark"
          size="xs"
          color="gray.1"
          radius="lg"
          onClick={() => {
            setAdding(true);
            setOverride(false);
          }}
        >
          I need to make changes
        </Button>
        {!vinVerified && !isLegacyVin && validMake && (
          <>
            <Text fw={600} c="dimmed" size="xs">
              or
            </Text>
            <Button
              c="dark"
              size="xs"
              color="gray.1"
              radius="lg"
              onClick={() => {
                setAdding(true);
                setOverride(true);
              }}
            >
              Proceed anyway
            </Button>
          </>
        )}
      </Flex>
    </DealerMode>
  );
};

const StyledOdometerForm = styled.div`
  background: var(--mantine-color-dark-8);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddVin = ({ onSuccess, originalData, additionalReqData, isOverride }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [newVinInfo, setNewVinInfo] = useState(null);
  const [value, setValue] = useState("");
  const [override, setOverride] = useState(false);
  const [showReading, setShowReading] = useState(false);
  const [vinNeedsModel, setVinNeedsModel] = useState(false);

  function onResetClick() {
    setResults(null);
    setNewVinInfo(null);
  }

  function onSubmit() {
    const nhtsaData = getDataFromNhtsaResults(
      results.nhtsa_results?.response
    ).reduce((acc, cur) => {
      acc[cur.server_key] = cur.value;
      return acc;
    }, {});

    const req = {
      ...additionalReqData,
      ...nhtsaData,
      vin: newVinInfo.vin,
      checksum_results: results.vin_checksum_results,
      nhtsa_results: results.nhtsa_results,
      odometer_value: value,
    };

    if (override) req.override = true;
    if (!req.make) req.make = "HARLEY-DAVIDSON";
    if (!req.model) req.model = newVinInfo.model;
    if (!req.year) req.year = newVinInfo.year;

    setLoading(true);

    axios
      .post(`/ride-challenge/verify-reading/`, req)
      .then(() => {
        toast.success("Verified!");
        setLoading(false);
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }

  function checkVin(formData) {
    const req = {
      ...formData,
    };

    setNewVinInfo(formData);

    setLoading(true);

    axios
      .post(`/user-vehicles/check-vin/`, req)
      .then(({ data }) => {
        setLoading(false);
        setResults(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const nhtsaSuccess = results?.nhtsa_results?.success;
  const isLegacyVin = newVinInfo && parseInt(newVinInfo.year) < 1981;
  const originalIsLegacyVin =
    originalData && parseInt(originalData.year) < 1981;
  const newVinVerified = isLegacyVin ? true : nhtsaSuccess;

  if (showReading) {
    return (
      <div>
        {newVinInfo && (
          <div>
            <Flex align="center" gap="xs">
              <Text size="xl" fw={600} c="white">
                VIN: {newVinInfo.vin?.toUpperCase()}
              </Text>
            </Flex>
            <Text c="dimmed" size="xs">
              manually entered data
            </Text>
            {/* {isOverride && ( */}
            {true && (
              <Box mb="lg">
                <VehicleCard info={newVinInfo} />
              </Box>
            )}
          </div>
        )}
        {isLegacyVin ? (
          <>
            <Text c="dimmed">
              NHTSA Results are not available for vehicles made before 1981.
            </Text>
            {/* <VehicleCard info={newVinInfo} /> */}
          </>
        ) : (
          <>
            {results.nhtsa_results && (
              <NhtsaResults results={results.nhtsa_results} />
            )}
          </>
        )}
        <Box mt="lg">
          <StyledOdometerForm>
            <OdometerForm value={value} onChange={(e) => setValue(e)} />
            {originalData.odometer_value && (
              <OdometerValueMismatch
                originalValue={originalData.odometer_value}
                newValue={value}
              />
            )}
            <Flex justify="center">
              <Button
                disabled={!value}
                mt="lg"
                onClick={onSubmit}
                loading={loading}
              >
                Submit
              </Button>
            </Flex>
            {loading && (
              <Text mt="lg" size="xs" fw={600} c="dimmed" align="center">
                Thank you for your patience. This may take up to 15 seconds.
              </Text>
            )}
          </StyledOdometerForm>
        </Box>
      </div>
    );
  }

  if (results) {
    const newVehicleMakeValid = isLegacyVin
      ? true
      : nhtsaMakeIsValid(results.nhtsa_results?.response);
    const newVinHasModel = newVinInfo?.model;
    const needsModel =
      !newVinHasModel &&
      !results.nhtsa_results?.response?.find((f) => f.Variable === "Model")
        ? true
        : false;

    return (
      <div>
        {originalData && (
          <Box mt="lg">
            <Text fw={600} c="white">
              Original Data
            </Text>
            <Text size="xl" fw={600} c="white">
              VIN: {originalData.vin?.toUpperCase()}
            </Text>
            {originalIsLegacyVin ? (
              <>
                <Text c="dimmed">
                  NHTSA Results are not available for vehicles made before 1981.
                </Text>
                <VehicleCard info={originalData} />
              </>
            ) : (
              <>
                <Box mb="lg">
                  <VehicleCard info={originalData} />
                </Box>
                {results.nhtsa_results && (
                  <NhtsaResults results={originalData?.nhtsa_results} />
                )}
              </>
            )}
          </Box>
        )}
        <Divider mt="lg" mb="lg" label="vs." />
        {newVinInfo && (
          <div>
            {newVehicleMakeValid && (
              <Text fw={600} c="white">
                Proposed Changes
              </Text>
            )}
            <Flex
              align={{
                base: "flex-start",
                xs: "center",
              }}
              gap="xs"
              direction={{
                base: "column",
                xs: "row",
              }}
            >
              <Text size="xl" fw={600} c="white">
                VIN: {newVinInfo.vin?.toUpperCase()}
              </Text>
              {newVehicleMakeValid && (
                <Button
                  onClick={() => {
                    onResetClick();
                  }}
                  color="dark.3"
                  size="compact-xs"
                  // variant="light"
                  radius="xl"
                  variant="subtle"
                >
                  edit VIN
                </Button>
              )}
            </Flex>
            {newVehicleMakeValid && (
              <Box mb="lg">
                <VehicleCard info={newVinInfo} />
              </Box>
            )}
            {/* {isOverride && !isLegacyVin && (
              <Box mb="lg">
                <VehicleCard info={newVinInfo} />
              </Box>
            )} */}
          </div>
        )}
        {isLegacyVin ? (
          <>
            <Text c="dimmed">
              NHTSA Results are not available for vehicles made before 1981.
            </Text>
            {/* <VehicleCard info={newVinInfo} /> */}
          </>
        ) : (
          <>
            {results.nhtsa_results && (
              <NhtsaResults results={results.nhtsa_results} />
            )}
          </>
        )}
        {newVehicleMakeValid && needsModel && (
          <Button
            mt="lg"
            size="lg"
            fullWidth
            onClick={() => {
              setOverride(true);
              setResults(null);
              setVinNeedsModel(true);
            }}
          >
            Proceed anyway
          </Button>
        )}
        {newVehicleMakeValid && !needsModel && (
          <Button
            mt="lg"
            size="lg"
            fullWidth
            onClick={() => {
              if (newVinVerified) {
                setOverride(false);
              } else {
                setOverride(true);
              }
              setShowReading(true);
            }}
          >
            {newVinVerified ? "Confirm Changes" : "Proceed anyway"}
          </Button>
        )}
        {!newVehicleMakeValid && (
          <Text mt="lg" c="red" fw={600} align="center">
            {makeErrorMessage}
          </Text>
        )}
        {/* <Box mt="lg">
          <StyledOdometerForm>
            <OdometerForm value={value} onChange={(e) => setValue(e)} />
          </StyledOdometerForm>
        </Box>
        <Button
          disabled={value.length !== 6}
          mt="lg"
          fullWidth
          onClick={onSubmit}
          loading={loading}
        >
          Submit
        </Button> */}
      </div>
    );
  }

  if (loading) {
    return (
      <Box
        mih={200}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Loader />
        <Text c="white" size="sm" fw={600} mt="md">
          Thank you for your patience. This may take up to 15 seconds.
        </Text>
      </Box>
    );
  }

  return (
    <div>
      <VinForm
        isOverride={isOverride || override}
        originalData={
          (isOverride || override) && newVinInfo
            ? {
                ...originalData,
                vin: newVinInfo.vin,
                make: newVinInfo.make,
                model: newVinInfo.model,
                year: newVinInfo.year,
              }
            : originalData
        }
        onSuccess={(vinData) => {
          checkVin(vinData);
        }}
      />
    </div>
  );
};

function NhtsaResults({ results = {} }) {
  const [isOpen, setOpen] = useState(false);
  const formattedItems = results.response
    ? getDataFromNhtsaResults(results.response)
    : [];

  const hasResults = JSON.stringify(results.response) !== "{}";

  return (
    <div>
      <Box
        component={UnstyledButton}
        onClick={() => {
          if (!hasResults) return;
          setOpen(!isOpen);
        }}
      >
        <Flex gap="xs" direction="column">
          <Flex gap="xs" align="center">
            <Text c={results.success ? "green" : "red"} fw={600} size="xl">
              NHTSA
            </Text>
            <Badge
              // leftSection={
              //   results.success ? (
              //     <IconCheck style={{ width: "60%", height: "60%" }} />
              //   ) : (
              //     <IconX style={{ width: "60%", height: "60%" }} />
              //   )
              // }
              size="xs"
              // color={results.success ? "green" : "red"}
              c={results.success ? "green" : "red"}
              bg={results.success ? "green.1" : "red.1"}
            >
              {results.success ? "Looks Good!" : "Needs Attention"}
            </Badge>
          </Flex>
        </Flex>
        {hasResults ? (
          <Text size="xs" c="dimmed">
            tap to {isOpen ? "hide" : "see"} NHTSA results
          </Text>
        ) : (
          <Text size="xs" c="dimmed">
            NHTSA results not available
          </Text>
        )}
      </Box>
      <Box
        mt="xs"
        style={{
          background: "var(--mantine-color-dark-6)",
          padding: "10px",
          borderRadius: "4px",
          border: "1px solid var(--mantine-color-dark-5)",
          borderTop: results.success
            ? "3px solid var(--mantine-color-green-7)"
            : "3px solid var(--mantine-color-red-7)",
          // borderBottom: results.success
          //   ? "3px solid var(--mantine-color-green-7)"
          //   : "3px solid var(--mantine-color-red-7)",
          // border: results.success
          //   ? "2px solid var(--mantine-color-green-7)"
          //   : "2px solid var(--mantine-color-red-7)",
        }}
      >
        {formattedItems.map((m, i) => (
          <div key={i}>
            <Text c="white">
              {m.text}: {m.value || "?"}
            </Text>
          </div>
        ))}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              <Box mt="xs">
                {results.response
                  .filter((f) => f.Variable === "Error Text")
                  .map((m, i) => (
                    <Text key={i} c="white">
                      {m.Value.replace("0 - ", "")}
                    </Text>
                  ))}
              </Box>
              {/* <Box
                mt="xs"
                style={{
                  background: "var(--mantine-color-dark-6)",
                  padding: "10px",
                  borderRadius: "6px",
                  border: results.success
                    ? "1px solid var(--mantine-color-green-5)"
                    : "1px solid var(--mantine-color-red-5)",
                }}
              >
                <Text mb="xs" c="white" fw={600} size="sm">
                  NHTSA Results
                </Text>
                {results.response
                  .filter((f) => f.Variable === "Error Text")
                  .map((m, i) => (
                    <Text key={i} c="white">
                      {m.Value.replace("0 - ", "")}
                    </Text>
                  ))}
              </Box> */}
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </div>
  );
}

function getDataFromNhtsaResults(results) {
  const baseProperties = [
    { key: "Make", text: "Make", server_key: "make" },
    { key: "Model", text: "Model", server_key: "model" },
    { key: "Model Year", text: "Year", server_key: "year" },
    { key: "Series", text: "Series", server_key: "series" },
  ];

  if (JSON.stringify(results) === "{}" && results)
    return baseProperties.map((m) => ({
      ...m,
      value: "",
    }));

  return baseProperties
    .map((m) => {
      let resultValue = results?.find((f) => f.Variable === m.key)?.Value;
      if (m.key === "Make" && !resultValue) {
        const manufacturer = results
          ?.find((f) => f.Variable === "Manufacturer Name")
          ?.Value?.toLowerCase();

        if (manufacturer && validManufacturers.includes(manufacturer)) {
          resultValue = "HARLEY-DAVIDSON";
        }
      }
      return {
        ...m,
        value: resultValue || "",
      };
    })
    .filter((f) => (f.key === "Series" && !f.value ? false : true));
}

export const OdometerForm = ({ value, onChange }) => {
  return (
    <div>
      <Text fw={600} c="white" mb="sm" align="center">
        Please input your odometer reading
      </Text>
      <Flex justify="center">
        <StyledNumberInput>
          <NumberInput
            size="lg"
            placeholder="----"
            min={0}
            value={value}
            isAllowed={(e) => {
              return e.value.length <= 6;
            }}
            onValueChange={(e) => {
              onChange(e.value);
            }}
            thousandSeparator=","
            hideControls
            allowDecimal={false}
            allowLeadingZeros={false}
            w={200}
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </StyledNumberInput>
        {/* <PinInput
          length={6}
          placeholder="-"
          label="Odometer"
          inputMode="numeric"
          autoFocus
          size="sm"
          value={value}
          onChange={(e) => onChange(e)}
        /> */}
      </Flex>
    </div>
  );
};

const StyledNumberInput = styled.div`
  input {
    text-align: center;
    background: linear-gradient(
      180deg,
      #a8e6cf 0%,
      #dcedc1 45%,
      #e8f3d6 50%,
      #dcedc1 55%,
      #a8e6cf 100%
    );
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: "DS-Digital", monospace;
    font-weight: 900;
    border: none;
    color: #2d5a27;
    font-size: 2.5em;
  }

  input::placeholder {
    color: rgba(45, 90, 39, 0.5);
    font-size: 0.65em !important;
  }
`;

const makeErrorMessage =
  "This vehicle is not a Harley-Davidson. Please enter another VIN.";
