import React from "react";
import { shallow } from "zustand/shallow";

import { OtherButton } from "./UserLocation";
import ViewTitle from "./ViewTitle";
import { VinSetup } from "@components/RideChallenge";
import RideChallengeHeader from "./RideChallengeHeader";

import useAppStore from "./ride-challenge-store";

export default function VehicleView() {
  const {
    vehicleInfo,
    setActiveStep,
    coords,
    effortId,
    setVehicleInfo,
    registrationInfo,
    verificationCode,
    location,
    user,
  } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      location: state.location,
      registrationInfo: state.registrationInfo,
      vehicleInfo: state.vehicleInfo,
      user: state.user,
      verificationCode: state.verificationCode,
      setActiveStep: state.setActiveStep,
      setVehicleInfo: state.setVehicleInfo,
    }),
    shallow
  );

  return (
    <div>
      <RideChallengeHeader />
      <ViewTitle
        title={"Motorcycle"}
        subtitle={`Tell us about your Harley-Davidson ®`}
      />
      <VinSetup
        verificationCode={verificationCode}
        onSuccess={() => {
          setActiveStep("vehicle_verification");
        }}
        additionalReqData={{
          ...coords,
          campaign_effort_id: effortId,
          location_id: location?.id,
          user_interaction_id: registrationInfo?.initial_user_interaction_id,
          user_id: user?.id,
          user_location_id: user?.user_location_id,
        }}
      />
    </div>
  );
}
