import React, { useEffect } from "react";
import { Card, Tabs } from "@mantine/core";
import axios from "axios";
import { shallow } from "zustand/shallow";

import GeneralSettings from "./GeneralSettings";

import useAppStore from "./song-settings-store";

const tabDefs = {
  general: "general",
};

export default function SongSettings({ songId, fetchSong }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  useEffect(() => {
    fetchSettings();
  }, []);

  function fetchSettings() {
    axios
      .get(`/songs/${songId}/settings/`)
      .then(({ data }) => {
        setSettings({ ...settings, ...data.response[0].settings });
      })
      .catch((err) => {
        //
      });
  }

  function updateSettings(newSettings) {
    const req = { song_id: songId, settings: newSettings };

    axios
      .put(`/songs/${songId}/settings/`, req)
      .then(() => {
        fetchSettings();
      })
      .then(() => fetchSong())
      .catch((err) => {});
  }

  function onUpdate(newSettings) {
    setSettings({ ...settings, ...newSettings });
    updateSettings({ ...settings, ...newSettings });
  }

  return (
    <Card>
      <Tabs
        onChange={(e) => onUpdate({ activeTab: e })}
        value={settings.activeTab}
      >
        <Tabs.List mb="xl">
          <Tabs.Tab value={tabDefs.general}>General</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={tabDefs.general}>
          <GeneralSettings onUpdate={(e) => onUpdate({ ...e })} />
        </Tabs.Panel>
      </Tabs>
    </Card>
  );
}
