import React, { useState, useEffect } from "react";
import {
  Group,
  Text,
  Box,
  Divider,
  NumberInput,
  TextInput,
} from "@mantine/core";
import { shallow } from "zustand/shallow";

import { Counter } from "@components/shared";
import useAppStore from "./contest-settings-store";
import { contestSettingsText } from "@components/Contest/helpers";

export default function GeneralSettings({ onUpdate, contestVariety }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const [tempSettings, setTempSettings] = useState({
    gauge_max_value: "",
  });

  useEffect(() => {
    setTempSettings({
      gauge_max_value: settings.gauge_max_value,
    });
  }, [JSON.stringify(settings)]);

  const counterFields = [
    {
      title: contestSettingsText.total_entries_allowed,
      keyName: "total_entries_allowed",
      value: settings.total_entries_allowed,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      {contestVariety === 4 && (
        <Box>
          <Divider mt="lg" mb="lg" />
          <NumberInput
            label="Maximum Gauge Value"
            value={tempSettings.gauge_max_value}
            onChange={(e) => setTempSettings({ gauge_max_value: e })}
            allowDecimal={false}
            allowLeadingZeros={false}
            allowNegative={false}
            onBlur={(e) => {
              if (
                e &&
                e.target.value &&
                e.target.value !== "" &&
                parseInt(e.target.value) > 0
              ) {
                onUpdate({ gauge_max_value: parseInt(e.target.value) });
              }
            }}
          />
        </Box>
      )}
      <Text size="xs" c="dimmed" mt="lg">
        *changes saved automatically
      </Text>
    </div>
  );
}
