import React, { useEffect, useState } from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";

import { TieredContestUserProgress } from "@components/Contest";

import useAppStore from "./ride-challenge-store";

export default function DashboardContests({ fetchData }) {
  const { coords, effortId, location, setAppLoading, user } = useAppStore(
    (state) => ({
      coords: state.coords,
      effortId: state.effortId,
      location: state.location,
      user: state.user,
      setAppLoading: state.setAppLoading,
    }),
    shallow
  );
  const [contests, setContests] = useState([]);

  useEffect(() => {
    fetchContests();
  }, []);

  function fetchContests() {
    const req = {
      ...coords,
      campaign_effort_id: effortId,
      location_id: location?.id,
      user_id: user.id,
    };

    setAppLoading(true);

    axios
      .post(`/ride-challenge/contest-results/`, req)
      .then(({ data }) => {
        setContests(data.response);
        setAppLoading(false);
      })
      .catch((err) => {
        setContests([]);
        setAppLoading(false);
      });
  }

  return (
    <div style={{ minHeight: "200px" }}>
      {contests.map((m) => (
        <div key={m.id}>
          <TieredContestUserProgress contestInfo={m} />
        </div>
      ))}
    </div>
  );
}
