import React, { useState } from "react";
import { TimeInput } from "@mantine/dates";
import {
  Card,
  Flex,
  Text,
  ActionIcon,
  Group,
  Button,
  Select,
} from "@mantine/core";
import dayjs from "dayjs";
import { IconTrash } from "@tabler/icons-react";

import { getUuid } from "@util/getUuid";

export const intervalValues = {
  hourly: 1,
  daily: 2,
  weekly: 3,
  monthly: 4,
  yearly: 5,
  ever: 6,
  custom: 7,
};

export const intervalValueStrings = Object.keys(intervalValues).map((m) => m);

export const ValueItem = ({ day, start, end, onRemoveClick }) => {
  return (
    <Group mb="md">
      <ActionIcon
        onClick={onRemoveClick}
        size="sm"
        mt="sm"
        radius="xl"
        style={{ marginTop: "0" }}
        variant="light"
        color="red"
      >
        <IconTrash />
      </ActionIcon>
      <Text>
        Day {day}: {start} - {end}
      </Text>
    </Group>
  );
};

const defaultFormValues = {
  start: "",
  end: "",
  day: "",
};

export const ValueAdd = ({ onAdd, dates }) => {
  const daysBetween = dayjs(dates.end).diff(dates.begin, "day") + 1;
  const daysBetweenArray = Array.from({ length: daysBetween }, (v, k) => k + 1);
  const dayOptions = daysBetweenArray.map((m, i) => ({
    label: `Day ${m} (${new Date(
      dayjs(dates.begin).add(i, "day")
    ).toLocaleDateString()})`,
    value: `${m}`,
  }));

  const [formValues, setFormValues] = useState(defaultFormValues);

  return (
    <div>
      <Select
        label="Day"
        data={dayOptions}
        placeholder="Select one..."
        value={formValues.day}
        onChange={(e) => setFormValues({ ...formValues, day: e })}
      />
      <Group grow>
        <TimeInput
          label="Start"
          format="12"
          value={formValues.start}
          onChange={(e) => {
            setFormValues({ ...formValues, start: e.target.value });
          }}
        />
        <TimeInput
          label="End"
          format="12"
          value={formValues.end}
          onChange={(e) =>
            setFormValues({ ...formValues, end: e.target.value })
          }
        />
      </Group>
      <Button
        fullWidth
        mt="md"
        onClick={() => {
          onAdd({ ...formValues, uuid: getUuid() });
          setFormValues(defaultFormValues);
        }}
        disabled={!formValues.end || !formValues.start || !formValues.day}
      >
        Add Value
      </Button>
    </div>
  );
};

export const IntervalItem = ({
  frequency = null,
  interval = null,
  values = [],
  onRemoveClick,
}) => {
  return (
    <Card>
      <Flex
        align="center"
        gap="xs"
        mb={intervalValues.custom === interval ? "xs" : "none"}
      >
        <Text fw={500}>
          {interval !== intervalValues.custom && (
            <React.Fragment>
              {frequency === -1 ? "Unlimited" : frequency} time
              {frequency === 1 ? "" : "s"},{" "}
            </React.Fragment>
          )}
          {intervalValueStrings[interval - 1]}{" "}
        </Text>
        {onRemoveClick !== undefined && (
          <ActionIcon
            onClick={onRemoveClick}
            size="sm"
            radius="xl"
            style={{ marginTop: "0" }}
            variant="light"
            color="red"
            title="Remove"
          >
            <IconTrash />
          </ActionIcon>
        )}
      </Flex>
      {interval === intervalValues.custom && (
        <React.Fragment>
          {values.map((v) => (
            <Text size="sm" c="dimmed" key={v.uuid}>
              Day {v.day}: {v.start} - {v.end}
            </Text>
          ))}
        </React.Fragment>
      )}
    </Card>
  );
};
