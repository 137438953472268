import React from "react";
import { Button, Grid, Group, Space, Divider } from "@mantine/core";
import { shallow } from "zustand/shallow";

import Intervals from "./Intervals";
import { Counter } from "@components/shared";
import useAppStore from "./contest-settings-store";

import { contestSettingsText } from "@components/Contest/helpers";

export default function Checkins({ beginDate, endDate, onUpdate }) {
  const { settings, setSettings } = useAppStore(
    (state) => ({
      settings: state.settings,
      setSettings: state.setSettings,
    }),
    shallow
  );

  const counterFields = [
    {
      title: contestSettingsText.check_in_value,
      keyName: "check_in_value",
      unlimited: false,
      max: 70,
    },
  ].map((m, i) => ({
    ...m,
    key: i + 1,
    value: settings.check_ins[m.keyName],
  }));

  return (
    <div>
      {counterFields.map((c) => (
        <Group key={c.key}>
          <Counter
            value={c.value}
            unlimited={c.unlimited}
            maxValue={c.max}
            onChange={(e) =>
              onUpdate({
                [c.keyName]: e,
              })
            }
          />
          <h3 style={{ margin: "0" }}>{c.title}</h3>
        </Group>
      ))}
      <Divider mb="lg" mt="lg" />
      <Intervals
        beginDate={beginDate}
        endDate={endDate}
        items={settings.check_ins.intervals}
        title="Check-In Interval"
        onChange={(e) => onUpdate({ intervals: e })}
      />
    </div>
  );
}
